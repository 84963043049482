import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from 'react-router-dom'
import { history } from './utils'
import { LoadingOutlined } from '@ant-design/icons'
import { AuthComponent } from '@/components/AuthComponent'
import Demo from "@/pages/Demo"
import Cooperator from "@/pages/Cooperator"
import Track from './pages/DashboardNew/track'
import Notfound from './components/Notfound'
import { lazy, Suspense, useEffect } from 'react'
import './App.css'


// 按需导入组件
const Login = lazy(() => import('./pages/Login'))
const Layout = lazy(() => import('./pages/Layout'))
const DashboardNew = lazy(() => import('./pages/DashboardNew'))
const Home = lazy(() => import('./pages/Home'))
const SupplyTool = lazy(() => import('./pages/SupplyTool'))
const Outhenticatedflow = lazy(() => import('./pages/Outhenticatedflow'))
const User = lazy(() => import('./pages/User'))
const OrderForGoods = lazy(() => import('./pages/OrderForGoods'))
const Warehouse = lazy(() => import('./pages/Warehouse'))
const HistoricalOrders = lazy(() => import('./pages/HistoricalOrders'))
const LargeScreen = lazy(() => import('./pages/LargeScreen'))
const JK = lazy(() => import('./pages/JK'))


function App() {

  return (
    // 路由配置
    <HistoryRouter history={history} >
      <div className="App">
        <Suspense
          fallback={
            <div
              style={{
                textAlign: 'center',
                marginTop: 200
              }}
            >
              <LoadingOutlined style={{ color: 'gray' }} />
              <span style={{ paddingLeft: '15px' }}>loading...</span>
            </div>
          }
        >
          <Routes>
            {/* 创建路由path和组件对应关系 */}
            {/* Layout需要鉴权处理的 */}
            <Route path='/' element={
               <AuthComponent>
              {/* <LargeScreen /> */}
               {/* <Layout /> */}
              </AuthComponent>
            }>
              {/* 默认二级路由 */}
              <Route index element={<DashboardNew />}></Route>
             

              {/* 我的雁群 */}
              <Route index path='home' element={<Home />}></Route>
              <Route path='supply-tool' element={<SupplyTool />} />
              <Route path='track' element={<Track />}></Route>
              {/* 个人中心 */}
              <Route path='user' element={<User />}></Route>
            </Route>
            {/* 驾驶舱轨迹 没有header */}

            <Route path='dashboard' element={<DashboardNew />}></Route>

            {/* 外链 我的雁群 */}
            <Route path='OrderForGoods' element={<OrderForGoods />}></Route>
            <Route path='Warehouse' element={<Warehouse />}></Route>
            <Route path='HistoricalOrders' element={<HistoricalOrders />}></Route>
            {/* 未找到 */}
            <Route path='*' element={<Notfound />}></Route>
            <Route path='login' element={<Login />}></Route>
            <Route path='Outhenticatedflow' element={<Outhenticatedflow />}></Route>
            <Route path='LargeScreen' element={<LargeScreen />}></Route>
             <Route path='jk' element={<JK />}></Route> 
             <Route path='dm' element={<Demo />}></Route>
             <Route path='co' element={<Cooperator />}></Route>
          </Routes>
        </Suspense>
      </div>
    </HistoryRouter>
  )
}

export default App
