import { useEffect, useState } from "react"
import { Space } from "antd"
import Map from "./components/Map/Map.js"
import SortList from "./components/sortList/index.js"
import TopModule from "./components/TopModule"
import RightModule from './components/LargeScreenModule/rightModule'
import LeftModule from './components/LargeScreenModule/leftModule'

import "./index.scss"
import { bigscreen } from "@/api"
import {currentEnvirement}from "@/utils/common"
export default function LargeScreen() {
  const [info, setinfo] = useState({});
  const [typeTabs, settypeTabs] = useState('my');
  const [currentSubsidiary, setcurrentSubsidiary] = useState({value:"",label:"总公司"});
const [showVedioInfo, setshowVedioInfo] = useState('');
  useEffect(() => {
    if (!localStorage.getItem("uid")) {
      window.location.href = "/login";
    }
    getbigscreen()
  }, [])
 

  let rateZoom = window.screen.width/1920;

  // if (currentEnvirement()=="PC"&&window.screen.width>1920) {
  //   rateZoom = window;
  // }
  const getbigscreen = async () => {
    try {
      const { data } = await bigscreen();

      setinfo(data)
    } catch (error) {
      console.log('bigscreen-大屏左面信息', error);
    }
    // debugger
  }
  const [sortListData,setsortListData]=useState([
    {name:'Hinddasdn Zinc Ltd',num:9572,unit:'吨'},
    {name:'Ksdald Metals',num:3589.5,unit:'吨'},
    {name:'Glsdasde plc',num:2393,unit:'吨'},
    {name:'CsdafLCO',num:1196.5,unit:'吨'},
    {name:'Landin',num:478.6,unit:'吨'},
  ])
  const sortListData2 = [
    {name:'**冶炼厂有限公司',num:113300,unit:'吨'},
    {name:'**金属冶炼厂有限公司',num:15520,unit:'吨'},
    { name: '**纯铁有限公司', num: 1231,unit:'吨'},
    {name:'**钢汇金属材料有限公司',num:5520,unit:'吨'},
    {name:'**有色金属有限责任公司',num:1231,unit:'吨'},
  ];
const [sortListData1, setsortListData2] = useState(sortListData2.map(m=>({...m,unit:"万元"})));
  return (
    <div className="cooperator_largescreen_container">
      <div className="cooperator_echarts_header">
{/* <div style={{color:'#fff'}}>{typeTabs}</div> */}
        <TopModule  settypeTabs={settypeTabs} setcurrentSubsidiary={setcurrentSubsidiary} />
      </div>
      <div className={typeTabs!="ck"?"map_container":"map_container map_container_storage"}>
        <Map rateZoom={rateZoom} typeTabs={typeTabs} currentSubsidiary={currentSubsidiary} showVedioInfo={showVedioInfo} />
        {/* {typeTabs!='ck'&&<Space size={[18,10]} className="cooperator_bottom_list">
          <SortList title="供应商排名" data={sortListData} currentSubsidiary={currentSubsidiary} />
          <SortList title="KA客户排名" data={sortListData1} currentSubsidiary={currentSubsidiary}/>
          </Space>} */}
      </div>
      <div className={typeTabs!="ck"?'cooperator_pictureLayerLeft':"cooperator_pictureLayerLeft cooperator_pictureLayerLeft_storage"}>
        <LeftModule info={info} typeTabs={typeTabs} ket={typeTabs} currentSubsidiary={currentSubsidiary}  setshowVedioInfo={setshowVedioInfo} />
      </div>
    
      <div  className={typeTabs!="ck"?'cooperator_pictureLayerRight':"cooperator_pictureLayerRight cooperator_pictureLayerRight_storage"}>
        {<RightModule info={info} rateZoom={rateZoom} currentSubsidiary={currentSubsidiary} typeTabs={typeTabs} setshowVedioInfo={setshowVedioInfo} />}
      </div>
    </div>
  )
}