
import React, { useState, useRef, useEffect } from 'react';
import './rightInventoryOverview.scss';
import { BorderBox1, ScrollBoard } from "./@jiaminghi/data-view-react"
import "@/index.scss";
const colorEnum = {
    '开航': '#50BA58',
    '中转离港': '#50BA58',
    '中转滞留': '#FF5722',
    '开航延误1天': '#F5212D',
    '中转装船': '#50BA58',
    '抵港': '#50BA58',

}
const config = {
  header: ['提单号', '港口', '到港日', '状态'],
  data: [
    // ['铅锌矿(EGLV752312351417)', '连云港', '03-12', '<span style="color:#50BA58;">开航</span>'],
  ],
  // ['铅锌矿(EGLV752312351417)', '连云港', '03-12', '<span style="color:#50BA58;">开航</span>'],
  // ['铅锌矿(MEDUBG2212262)', '连云港', '02-13', '<span style="color:#50BA58;">开航</span>'],
  // ['铅精矿(HDMUDALA4888700)', '防城港', '01-14', '<span style="color:#50BA58;">中转</span>'],
  // ['铅精矿(HDMUDALA4888700)', '防城港', '02-25', '<span style="color:#F5212D;">开航延误1天</span>'],
  // ['铅锌矿(W5648776226)', '黄埔港', '03-26', '<span style="color:#50BA58;">卸货</span>'],
  // ['铜矿(MEDUL4856845)', '黄埔港', '03-17', '<span style="color:#50BA58;">提货</span>'],
  // ['铜矿砂及精矿(MEDUL9764563)', '黄埔港', '03-11', '<span style="color:#50BA58;">进场</span>'],
  // ['铅锌矿(MAERSK ALTAIR)', '青岛港', '04-19', '<span style="color:#50BA58">提箱</span>'],
  // ['铜矿(EGLV1424444544948)', '宁波港', '02-20', '<span style="color:#F5212D;">中转延误2天</span>'],
  // ['铅精矿(MEDUE32527432)', '宁波港', '01-21', '<span style="color:#50BA58;">开航</span>'],
  //   ['铅锌矿(EGLV752312351417)', '连云港', '03-12', '<span style="color:#50BA58;">开航</span>'],
  //   ['铅锌矿(MEDUBG2212262)', '连云港', '02-13', '<span style="color:#50BA58;">开航</span>'],
  //   ['铅精矿(HDMUDALA4888700)', '防城港', '01-14', '<span style="color:#50BA58;">中转</span>'],
      
  columnWidth: [240, 140, 190,  130],
  headerBGC: 'rgba(0, 123, 162, 0)',
  headerHeight: '40',
  oddRowBGC: 'rgba(0, 123, 162, 0)',
  evenRowBGC: 'rgba(0, 123, 162, 0)',
  rowNum:12,
  waitTime:4000,
  // hoverPause: true
}
let dataList=  [
  { "name":"泥炭238804001" , "port":"广州-南沙","date":"2024-07-17","status":"中转离港" }, 
  { "name":"泥炭EGLV501495005330" , "port":"广州-南沙","date":"2024-07-27","status":"中转离港" }, 
  { "name":"泥炭MEDUVF275618" , "port":"青岛","date":"2024-08-04","status":"中转离港" }, 
  { "name":"泥炭MEDUVF276228" , "port":"大连","date":"2024-08-28","status":"中转离港" }, 
  { "name":"泥炭MEDUVF273860" , "port":"青岛","date":"2024-08-25","status":"中转离港" }, 
  { "name":"泥炭EGLV501495006760" , "port":"广州-南沙","date":"2024-08-25","status":"开航" }, 
  { "name":"泥炭EGLV501495006752" , "port":"广州-南沙","date":"2024-08-25","status":"中转装船" }, 
  { "name":"泥炭MEDUVF276202" , "port":"天津新港","date":"2024-08-15","status":"中转离港" }, 
  { "name":"泥炭EGLV501495006417" , "port":"广州-南沙","date":"2024-08-03","status":"中转离港" }, 
  { "name":"泥炭HLCUTL1240412531" , "port":"青岛","date":"2024-07-30","status":"中转离港" }, 
  { "name":"泥炭HLCUTL1240412520" , "port":"青岛","date":"2024-07-30","status":"中转离港" }, 
  { "name":"泥炭YMJAN830007449" , "port":"广州-南沙","date":"2024-07-03","status":"中转离港" }, 
  { "name":"泥炭238701667" , "port":"上海","date":"2024-07-01","status":"中转离港" }, 
  { "name":"泥炭EGLV501495005313" , "port":"广州-南沙","date":"2024-07-18","status":"中转离港" }, 
  { "name":"泥炭MEDULY099995" , "port":"广州-南沙","date":"2024-07-28","status":"中转滞留" }, 
  { "name":"泥炭EGLV501495003949" , "port":"广州-南沙","date":"2024-07-05","status":"中转离港" }, 
  { "name":"泥炭EGLV501490002456" , "port":"广州-南沙","date":"2024-07-05","status":"中转离港" }, 
  { "name":"泥炭LVA0149734" , "port":"青岛","date":"2024-06-27","status":"抵港" }, 
  { "name":"泥炭LVA0149733" , "port":"青岛","date":"2024-06-27","status":"抵港" }, 
  { "name":"泥炭EGLV501490003461" , "port":"广州-南沙","date":"2024-07-18","status":"中转离港" }, 
  { "name":"泥炭HLCURI1240411708" , "port":"广州-南沙","date":"2024-07-31","status":"中转离港" }, 
  { "name":"泥炭HLCUTL1240312405" , "port":"青岛","date":"2024-07-10","status":"中转离港" }, 
  { "name":"泥炭MEDUFV028743" , "port":"广州-南沙","date":"2024-08-17","status":"中转离港" }, 
  { "name":"泥炭HLCUHAM2403BLAH0" , "port":"广州-南沙","date":"2024-07-03","status":"中转离港" }, 
  { "name":"泥炭HLCUHAM2403BLAD6" , "port":"广州-南沙","date":"2024-07-03","status":"中转离港" }, 
 ];
 dataList.forEach((e,j)=>{
  // 按安装日期排序
  config.data.sort((a, b) => {
  const dateA = new Date(a.installDate);
  const dateB = new Date(b.installDate);
  return dateA - dateB;
});
  config.data.push([e.name,e.port,e.date,`<span style="color:${colorEnum[e.status]};">${e.status}</span>`])
 })
 let config1 = {
  header: ['港口/堆场',  '数量(包)','数量(托)'],
  data: [
      ['青岛仓(恒顺丰)',  '175',  '10200'],
     
      ['青州仓(恒晟仓)',  '301',  '14015'],
      
      ['成都仓（捷瑞）',  '690',  '0'],
     
      ['佛山仓(恒晟仓))',  '9728',  '41815'],
     

  ],
  // index: true,///<span style="color:#e7bcf3;">行10列2</span>
  // columnWidth: ['200', '90'],
  // align: ['center', 'center', 'center', 'center', 'center'],
  // carousel: 'page'
  headerBGC: 'rgba(0, 123, 162, 0)',
  headerHeight: '40',
  oddRowBGC: 'rgba(0, 123, 162, 0)',
  evenRowBGC: 'rgba(0, 123, 162, 0)',
  hoverPause: false,
  waitTime:4000,
  rowNum :3,
}
function RightInventoryOverview({setshowVedioInfo,currentSubsidiary}) {


  return (
    <div style={{ width: '100%' }}>
      <div className='cooperator_rightInventoryOverview'>
        <div className='operate'></div>
        <div className='cooperator_scrollBoardBox'>
          <ScrollBoard config={config} style={{ width: '100%',height:'100%' }} oddRowBGC='#007ba233' />
          {/* <ScrollBoard config={config1} style={{ width: '100%', }}  /> */}
        </div>


      </div>
      <div className='cooperator_rightInventoryOverview marginTopData'>
        <div className='operateTwo'></div>
        <div className='cooperator_scrollBoardBox cooperator_hasedWarehouse'>
          <ScrollBoard config={config1} style={{ width: '100%',height:'100%'  }}  />
        </div>


      </div>
    </div>
  );
}
export default RightInventoryOverview