import { useState } from "react";
import { Space, Divider } from "antd";
import dvedio from "@/assets/d_vedio.jpg";
import RenderShip from "./renderShip";

const RenderStorageHouse = ({ item, showDetail, storageType = '', billList = [], handleVedio, title }) => {
  const [clickedIndex, setclickedIndex] = useState(-1);
  console.log(billList, item)
  return <Space size={[0, 0]} direction="vertical" className={`storage_container_cooperator storage_${storageType}_container`}>
    <div className="storage_name_btn">
      {/* {storageType} */}
      <span>{title}</span>
     
{storageType == "warehouse" &&<span className="detail_btn" onClick={showDetail} style={{cursor:"pointer"}}> 查看详情</span>}
    </div>
    <div className="goods_info_container">
      {billList.length == 0 ? <div className="goods_info">
        <span>{item?.title}: </span>
        <span> <span className="goods_num">{item?.number} </span>{item?.itemUnit || "包"}</span>
      </div> : 
      // 多个商品/
      billList.map((m, billIndex) =>

      (<div key={m?.code} className="goods_info bill_item" onClick={() => setclickedIndex(billIndex)}>
        <span> {m?.code}</span>
        {billIndex == clickedIndex && <div className="bill_item_detail">
          {/* 暂时全部默认展示ship的格式，后续根据需求再优化 */}
          {storageType=="hz"?<div className="bill_item_detail_container">
            <h4>{m?.code}</h4>
            <span>{m?.title}</span>
            {item?.number && <span> {item?.number} {item?.itemUnit || "吨"}</span>}
            {m?.warehouse && <span>{m?.warehouse}</span>}
            {storageType == "warehouse" && <> <Divider className="bill_item_detail_divider" />
              <div onClick={() => handleVedio(m?.code)} style={{ textAlign: 'center', cursor: "pointer" }}><img className="vedio_icon" src={dvedio} /> 实时监控</div>
            </>}
          </div>:
          <RenderShip props={m} storageType={storageType} />

          }
          
          </div>}
      </div>))}
    </div>
  </Space>
}

export default RenderStorageHouse;