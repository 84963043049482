import { Space } from "antd"
import moment from "moment"
import ship_icon from "@/assets/p_icon.svg"
import port_icon from "@/assets/p_icon_port.svg"
import warehouse_icon from "@/assets/p_icon_warehouse.svg"

import "./commonModul.scss"
const RenderShip = ({ props,storageType="ship" ,showDetail}) => {
    const storageType_icon = ()=>{
        console.log(storageType,'storageType');
        if(storageType=="ship"){
            return ship_icon
        }
        if(storageType=="port"){
            return port_icon
        }
        if(storageType=="warehouse"){
            return warehouse_icon
        }
    }
    return <div className={storageType=="ship"? "ship_container":`ship_container ${storageType}_container`} >
        <div className="ship_base_info">
            <Space align="center">
            <span className="ship_code"> {props?.code}</span>
            {storageType == "warehouse" &&<span className="warehouse_detail" onClick={showDetail} style={{cursor:"pointer"}}> 查看详情</span>}
            </Space>
            
            <div className="port_time_content">

                <Space size={[0, 0]} className="port">
                    <img src={storageType_icon()} style={{ paddingRight: 7}} />
                    <Space direction="vertical" size={[0, 0]}>
                        <span>{props?.startPort}</span>
                        <span>{props?.endPort}</span>
                    </Space>

                </Space>

                <Space direction="vertical" size={[0, 0]} className="time">
                    {<span>{props?.atd?"ATD: ":"ETD"} : {(props?.atd||props?.etd) && moment(props?.atd||props?.etd).format('YY-MM-DD')}</span>}
                    {<span>{props?.ata?"ATA: ":"ETA"} : {(props?.ata||props?.eta) && moment(props?.ata||props?.eta).format('YY-MM-DD')}</span>}
                </Space>

            </div>
        </div>
        <Space size={[10, 0]} wrap className="ship_tags">
            <span style={{ overflow: 'hidden' }}> {props?.title}</span>
            <span> {props?.number}{props?.itemUnit || "吨"}</span>
            <span> {props?.shipCompany}</span>
            <span> {props?.shipType}</span>
            {props?.warehouse && <span> {props?.warehouse}</span>}
            {props?.voyage && <span> {props?.voyage}</span>}
        </Space>
    </div>
}
export default RenderShip