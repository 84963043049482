import React from 'react';
import './rightModule.scss';
import PieChart from '@/components/LargeScreenModule/pieChart'
import OverviewPort from './OverviewPort'

import TransitInventory from './transitInventory'
import InventoryStatus from "./cooperatorLineEchart"
import WordCloud from '@/components/LargeScreenModule/wordCloud'
import { BorderBox1, ScrollBoard } from "./@jiaminghi/data-view-react"
function RightTradeOverview(props) {
  const { OverviewVal, OverviewOne, Proportion, warningData, } = props.data
  // const config1 = {
  //   header: ['提单号', '港口', '到港日', '信用证到期日','浮动盈亏'],
  //   data: [
  //     ['EGLV751231251417', '连云港', '01-12','01-28',  '<span style="color:#F5212D;">+24000</span>'],
  //     ['MEDUBG122326', '连云港', '02-13','01-17<span style="color:#F5212D;">即将逾期</span>', '<span style="color:#F5212D;">+45320</span>'],
  //     ['MEDUBG1231112', '防城港', '01-14', '--','<span style="color:#50BA58;">-1500</span>'],
  //     ['W231233226', '黄埔港', '02-17','02-18', '<span style="color:#F5212D;">+24040</span>'],
  //     ['MEDUL9231223', '黄埔港', '02-19','--', '<span style="color:#50BA58;">-22300</span>'],
  //     ['MEDUL9123122', '黄埔港', '01-18', '--','<span style="color:#F5212D;">+24200</span>'],
  //     ['MAERSK ALTAIR(散货)', '青岛港', '04-19','02-28', '<span style="color:#50BA58;">正常</span>'],
  //     ['EGLV142299991948', '宁波港', '02-20','--', '<span style="color:#F5212D">+19000</span>'],
  //     ['MEDUE3232232', '宁波港', '02-21','--', '<span style="color:#F5212D">+1400</span>']
  //   ],
  //   columnWidth: [200,200,200,200,200,],
  //   align: ['center','center','center','center'],
  //   headerBGC: 'rgba(0, 123, 162, 0)',
  //   headerHeight: '40',
  //   oddRowBGC: 'rgba(0, 123, 162, 0)',
  //   evenRowBGC: 'rgba(0, 123, 162, 0)',
  // }
  return (
    <div className='cooperator_rightData'>
      <div className='goodsData'></div>
      <div className='cooperator_goodsTransit'>
        <div style={{ display: 'flex' }}>
          <div className='goodsTransitLeft'></div>
          <div className='goodsTransitBoxRight'>
            <div className='goodsTransitBoxRightOne'>在途货物(箱)</div>
            <div className='goodsTransitNumber'><span className='goodsNumber'>{OverviewVal?.transit}</span>  </div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className='goodsTransitCenter'></div>
          <div className='goodsTransitBoxRight'>
            <div className='goodsTransitBoxRightOne'>到港货物(箱) </div>
            <div className='goodsTransitNumber'><span className='goodsNumber'>{OverviewVal?.inventory}</span>  </div>
          </div>
        </div>

      </div>
      <div className='port'>
        <div>
          <div className='category'>自营进口</div>
          <div style={{ display: 'flex' }} className='categoryBorder'>
            <div className='categoryBorderLeft'></div>
            <div className='categoryBorderCenter'></div>
            <div className='categoryBorderRight'></div>
          </div>
        </div>
        <div className='InTransitTree'>
          <div className='InTransitTreeOne'>
            <div className='measure'>在途量(箱)</div>
            <div className='measureNub'>505</div>
          </div>
          <div className='InTransitTreeTwo'>
            <div className='measure'>到港量(箱)</div>
            <div className='measureNub'>177</div>
          </div>
        </div>
        <div>
          <div className='category'>委托进口</div>
          <div style={{ display: 'flex' }} className='categoryBorder'>
            <div className='categoryBorderLeft'></div>
            <div className='categoryBorderCenter'></div>
            <div className='categoryBorderRight'></div>
          </div>
        </div>
        <div className='InTransitTree'>
          <div className='InTransitTreeOne'>
            <div className='measure'>在途量(箱)</div>
            <div className='measureNub'>555</div>
          </div>
          <div className='InTransitTreeTwo'>
            <div className='measure'>到港量(箱)</div>
            <div className='measureNub'>350</div>
          </div>
        </div>
      </div>

      <div>
        <div className='category'>港口/堆场到港占比</div>
        <div style={{ display: 'flex' }} className='categoryBorder'>
          <div className='categoryBorderLeft'></div>
          <div className='categoryBorderCenter'></div>
          <div className='categoryBorderRight'></div>
        </div>
      </div>

      <div className='inventoryProportion'>
        
        <InventoryStatus currentSubsidiary={props.currentSubsidiary} data={Proportion} />
      </div>

     
    </div>
  );
}
export default RightTradeOverview