import { useState, useEffect } from "react";
import { List, Space } from "antd";
import styles from "./index.module.scss"
import "./index.scss"
import { ScrollBoard } from "../LargeScreenModule/@jiaminghi/data-view-react"

const SortList = ({configData }) => {
  const configList={
    data: configData.map((m, i) => {
      return [`<div class="item_container demo_item${i} demo_item">
          <div class="item_left">
              <i>${i + 1}</i>
              <span> ${m?.name}</span>
          </div>
          <div>${m?.price}(元)</div>
      </div>`]
    }),
    hoverPause: false,
    evenRowBGC: "#f5f5f5",
    // oddRowBGC: "#fff",
    // rowHeight: '40',
    rowNum:5,
    waitTime:4000,
  };
  return <div className='ScrollBoard_container' >
    <ScrollBoard config={configList} style={{ width: '100%',height: '100%' }} />
  </div>
}
export default SortList