// 封装axios
// 实例化  请求拦截器 响应拦截器

import axios from "axios";
import { getToken, getUserCookie } from "./token";
import { history } from "./history";
import { message } from "antd";
import { getTestnetHeaders, getLoginUrl } from "@/api/config";

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000,
  //withCredentials: true //  允许携带cookie
});

// 添加请求拦截器
http.interceptors.request.use(
  (config) => {
    const token = getToken();
    const headers = getTestnetHeaders();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    config.headers = { ...config.headers, ...headers }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  (response) => {
    //  范围内的状态码都会触发该函数。
    const { data } = response;
    // debuggerhttp://localhost:3000/localhost3000/login
    if (data.code === "401" || data.code === "404") {
      console.log('data.code-404', error);
      // window.location.href = getLoginUrl();
    }
    if (data.code === "1002" || data.msg === "请重新登录") {

      window.location.href = getLoginUrl();
    }

    if (!data.success || data.code != '0000') {
      //过滤驾驶舱错误
      if (!response.config.url.includes("getBillLatestCargoAis")) {
        if (
          (data && data.data && data.data.res == true) ||
          (data && data.data && data.data.res == false)
        ) {
        } else if (data.code == "0000") {
        } else {
          message.error(data?.msg);
        }
      }
      if (data.code) {
        switch (data.code) {
          case "200015":
            // 装填时间为空
            //console.log(`驾驶舱错误code${data.code}：`, data.msg)
            break;
          case "200006":
            // 装填时间为空
            //console.log(`驾驶舱错误code${data.code}：`, data.msg)
            break;
          case "9000":
            // 装填时间为空
            // message.error('装填时间为空');
            break;

          default:
            break;
        }
      }
    }
    return response.data;
  },
  (error) => {
    console.log("error", error);
    if (error.code === "ECONNABORTED") {
      // message.error("网络繁忙，请重试");
    }

    if (error.response.status === 401) {
      // window.location.href = getLoginUrl();
    }

    if (error.response.status === 500) {
      // message.error("服务器繁忙，请稍后再试");
    }

    return Promise.reject(error);
  }
);

export { http };
